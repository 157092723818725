import { Injectable } from '@angular/core';
import { SignalStatesService } from './signal-states.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UpdateStageService {
  headerOptions: any;
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient, private signalStatesService: SignalStatesService, private router: Router) { }

  updateStage(stageObject: { type: string; status: string; step?: any }, stageId: number = 0): void {
    const onboardingId = this.signalStatesService.loggedStorageSignal().onboarding.id;

    this.http.patch<any>(`${this.baseUrl}v1/onboarding/${onboardingId}/update_stage/`, stageObject, this.setHeaderOptions()).subscribe(
      {
        next: (response: any) => {
          console.log(response);
          this.signalStatesService.updatePendingStagesSignal(response);
          if (stageId === 0) {
            return;
          }
          let stageNumber = stageId + 1;

          if (stageNumber === 4 && this.signalStatesService.loggedStorageSignal().onboarding.master_church.is_site_active) {
            stageNumber++;
            if (!this.signalStatesService.formsValuesSignal().hasCnpj) {
              stageNumber++;
            }
          }
          if(stageNumber === 6) {
            const item = response.find((item: any) => item.id === 6);
            console.log('item', item);
            stageNumber = item.step.step_chosen === 'wont_have' ? item.id + 1 : stageNumber;
            console.log('stageNumber', stageNumber);
          }
          this.router.navigateByUrl(`${this.signalStatesService.menuActiveRoute()[stageNumber - 1].route}`);
        },
        error: (error) => {
          console.error(error);
          this.router.navigateByUrl(`${this.signalStatesService.menuActiveRoute()[stageId].route}`);
        }
      }
     
    );
  }

  // Options
  setHeaderOptions() {
    return {
      headers: {
        Channel: 'onboarding',
        Authorization: this.signalStatesService.loggedStorageSignal().basic_user_person.authorization,       
      }
    }
  }
}
